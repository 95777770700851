@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-color: #f9f9f9;
    margin: 0;
    font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: block;
}

#root {
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.notistack-SnackbarContainer {
    margin-top: 55px;
}